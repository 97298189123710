<template>
  <div
    :class="{ 'bg-[#e6eef1]': width > minWidth }"
    class="app-about px-5 mb-10 sm:px-28 sm:pt-28 sm:flex justify-between"
  >
    <div class="info flex-shrink pr-5">
      <h3 class="flex items-center">
        <span class="w-2 h-2 rounded-full inline-block mr-2 bg-red-700"></span
        ><span class="about text-sm font-normal sm:text-3xl">ABOUT US</span>
      </h3>
      <h1
        :class="{ 'font-extrabold': !en }"
        class="uppercase second-font sm:leading-[4rem] font-bold mt-4 text-2xl sm:text-5xl leading-snug"
      >
        {{ t("about.title") }}
      </h1>

      <div
        v-if="width <= minWidth"
        class="cover sm:w-1/2 flex-shrink-0 sm:pl-5 mt-12 relative overflow-hidden"
      >
        <div @click="playVideo" class="relative sm:h-96 h-44">
          <video
            class="w-full h-full object-cover sm:rounded-3xl rounded-sm"
            ref="videoElementRef"
            src="../assets/video/v2.mp4"
            poster="~/assets/about/about.png"
          ></video>
          <div
            v-show="!isPlaying"
            class="cursor-pointer bg-black rounded-full opacity-50 text-white p-2.5 absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          >
            <IconPlayerPlayFilled />
          </div>
        </div>
      </div>

      <div class="address mt-12 font-light">
        <div class="inline-flex items-center">
          <span
            class="sm:w-2 sm:h-2 h-1.5 w-1.5 rounded-full inline-block mr-2 bg-black"
          ></span>
          <span class="address-name sm:text-2xl text-sm">New York</span>
        </div>
        <div class="inline-flex items-center ml-10">
          <span
            class="sm:w-2 sm:h-2 h-1.5 w-1.5 rounded-full inline-block mr-2 bg-black"
          ></span>
          <span class="address-name sm:text-2xl text-sm">Tokyo</span>
        </div>
      </div>

      <article
        :class="{ 'font-normal': !en }"
        class="mt-8 text-sm sm:text-2xl capitalize"
      >
        {{ t("about.description") }}
      </article>
    </div>
    <div
      v-if="width > minWidth"
      class="cover sm:w-1/2 flex-shrink-0 sm:pl-5 mt-12 relative overflow-hidden"
    >
      <div @click="playVideo" class="relative sm:h-96">
        <video
          class="w-full h-full sm:h-full object-cover rounded-3xl"
          ref="videoElementRef"
          src="../assets/video/v2.mp4"
          poster="~/assets/about/about.png"
        ></video>
        <div
          v-show="!isPlaying"
          class="cursor-pointer bg-black rounded-full opacity-50 text-white p-2.5 absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        >
          <IconPlayerPlayFilled />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss"></style>
<script lang="ts" setup>
import { IconPlayerPlayFilled } from "@tabler/icons-vue";

const { t, locale, locales } = useI18n();
const en = locale.value === "en";

const videoElementRef = ref<HTMLVideoElement | null>(null);

const isPlaying = ref(false);
function playVideo() {
  const video = videoElementRef.value as HTMLVideoElement;
  if (video.paused) {
    video.play();
    isPlaying.value = true;
  } else {
    video.pause();
    isPlaying.value = false;
  }
}

let width = ref(0);
const minWidth = 640;

const updateWidth = () => {
  width.value = window.innerWidth;
};

onMounted(() => {
  updateWidth();
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});
</script>
