<template>
  <div class="app-works bg-black sm:pt-28 mt-12 pt-5">
    <h1 class="flex items-center sm:pt-8 pt-1 sm:px-36 px-6 sm:pb-6">
      <span
          class="sm:w-2 sm:h-2 h-1.5 w-1.5 rounded-full inline-block mr-2 bg-red-700"
      ></span>
      <span class="title sm:text-4xl text-sm text-white">WORK</span>
    </h1>

    <nuxt-link
        :to="width > minWidth ? localPath('/works/aww') : localPath('/work')"
    >
      <div class="relative ml-5">
        <div
            class="absolute sm:left-12 sm:bottom-28 left-5 bottom-4 text-white sm:text-6xl text-2xl"
        >
          <div class="text-sm sm:text-6xl">AWW</div>
          <div class="text-sm sm:text-6xl">#Creative Direction #Branding</div>
        </div>
        <img
            class="w-full sm:h-10/12 h-full object-cover sm:mt-16 mt-6"
            src="../assets/images/works-1.jpg"
            alt=""
        />
      </div>
    </nuxt-link>

    <nuxt-link
        :to="width > minWidth ? localPath('/works/obagi') : localPath('/work')"
        >
      <div class="sm:mt-24 relative ml-5">
        <div
            class="absolute sm:left-12 sm:bottom-28 left-5 bottom-8 text-white sm:text-6xl text-2xl"
        >
          <div class="text-sm sm:text-6xl">OBAGI</div>
          <div class="text-sm sm:text-6xl">#E-Commerce #CGI #3D</div>
        </div>
        <img
            class="w-full sm:h-10/12 object-cover mt-16"
            src="../assets/images/works-2.jpg"
            alt=""
        />
      </div>

    </nuxt-link>

    <nuxt-link
    :to="width > minWidth ? localPath('/works/neo') : localPath('/work')"
    >
      <div class="mt-14 ml-5 relative sm:pb-80">
        <div
            class="absolute sm:left-12 sm:bottom-96 left-5 bottom-8 text-white sm:text-6xl text-2xl"
        >
          <div class="text-sm sm:text-6xl">NEO</div>
          <div class="text-sm sm:text-6xl sm:mt-3">#Event #Game #Marketing</div>
        </div>
        <img
            class="w-full sm:h-full object-cover mt-16"
            src="../assets/images/works-3.jpg"
            alt=""
        />
        <a
            href="#"
            class="absolute invisible sm:visible text-gray-300 sm:bottom-52 right-16 sm:text-4xl sm:font-light underline"
        >ALL WORKS</a
        >
      </div>
    </nuxt-link>
  </div>
</template>
<script setup lang="ts">
const localPath = useLocalePath();
let width = ref(0);
const minWidth = 640;

const updateWidth = () => {
  width.value = window.innerWidth;
};

onMounted(() => {
  updateWidth();
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});
</script>