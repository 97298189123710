<template>
  <div class="sm:mx-28 mx-5 sm:mt-32">
    <h1 class="flex items-center">
      <span
        class="sm:w-2 sm:h-2 w-1.5 h-1.5 rounded-full inline-block sm:mx-5 mx-2 bg-red-700"
      ></span>
      <span class="second-font title text-md sm:text-3xl">SERVICES</span>
    </h1>

    <div class="sm:mt-20 mt-5 sm:-mx-6">
      <div
        v-for="(item, index) in data"
        :key="index"
        class="sm:px-6 sm:w-1/2 sm:inline-block sm:mb-24 mb-5"
      >
        <nuxt-link
          :to="localPath('/service')"
          class="block bg-[#dfe7e9] sm:border-t-[3px] border-t-[1px] border-black transition-all hover:shadow-md group cccc hover:stroke-white"
        >
          <div
            class="header sm:px-4 px-2 border-b border-black/60 sm:py-6 py-0.5 transition-all hover:stroke-white duration-500 flex group-active:bg-[var(--header-color)] group-focus:bg-[var(--header-color)] group-hover:bg-[var(--header-color)] justify-between"
            :style="`--header-color:${item.color}`"
          >
            <div class="title sm:text-2xl flex items-center flex-grow">
              <img
                class="branding-logo mr-3 sm:w-7 sm:h-7 h-5 w-5 object-contain"
                alt="logo"
                :src="item.logo.default"
              />
              <h1
                class="svg-title uppercase sm:text-4xl font-bold relative block w-full"
              >
                <svg class="h-[2rem] sm:h-[4rem] w-full">
                  <text text-anchor="left" x="0" y="70%" class="bbbb">
                    {{ item.title }}
                  </text>
                </svg>
              </h1>
            </div>
            <nuxt-link
              :to="item.more"
              class="more relative right-1 sm:right-0 flex items-center flex-grow-0"
            >
              <span class="relative pt-2 text-xs sm:text-xl">MORE</span>
              <img
                class="arrow sm:h-6 h-4 pt-2 sm:ml-2 ml-1 object-contain"
                src="~/assets/arrow.png"
                alt=""
              />
            </nuxt-link>
          </div>

          <div class="content">
            <ul class="sm:py-16 py-[26px] flex justify-around">
              <li
                class="text-center w-1/3"
                v-for="(item2, index2) in item.items"
                :key="`aaa-${index2}`"
              >
                <div class="icon inline-block">
                  <img
                    class="sm:w-10 sm:h-10 w-5 h-5"
                    :src="item2.icon.default"
                    alt=""
                  />
                </div>
                <br />
                <div
                  class="title font-normal mx-1 sm:text-[16px] inline-block sm:mt-6 text-xs"
                >
                  {{ item2.title }}
                </div>
              </li>
            </ul>
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t, locale, locales } = useI18n();

const en = locale.value === "en";
const localPath = useLocalePath();

const data = ref([
  {
    title: "BRANDING",
    logo: await import("~/assets/service/branding-logo.png"),
    more: "#",
    color: "#105ff6",
    items: [
      {
        icon: await import("~/assets/service/strategic.png"),
        title: en ? "Strategic Branding" : "ブランド戦略の策定",
      },
      {
        icon: await import("~/assets/service/visual.png"),
        title: en ? "Visual Branding" : "ビジュアルブランディング",
      },
      {
        icon: await import("~/assets/service/development.png"),
        title: en ? "Development" : "デザインシステム開発",
      },
    ],
  },
  {
    title: "Marketing",
    logo: await import("~/assets/service/marketing-logo.png"),
    more: "#",
    color: "#69a2e6",
    items: [
      {
        icon: await import("~/assets/service/research.png"),
        title: en ? "Research & Analysis" : "市場調査と分析",
      },
      {
        icon: await import("~/assets/service/digital.png"),
        title: en ? "Digital Marketing" : "デジタルマーケティング",
      },
      {
        icon: await import("~/assets/service/social.png"),
        title: en
          ? "Social Media Management"
          : "ソーシャルメディア マーケティング",
      },
    ],
  },
  {
    title: "technology solution",
    logo: await import("~/assets/service/technology-logo.png"),
    more: "#",
    color: "#add445",
    items: [
      {
        icon: await import("~/assets/service/web3.png"),
        title: en ? "Web3/blockchain" : "Web3/ブロックチェーン開発",
      },
      {
        icon: await import("~/assets/service/ar.png"),
        title: en ? "AR & VR & MR" : "AR/VR&MR 開発",
      },
      {
        icon: await import("~/assets/service/aigc.png"),
        title: "AIGC",
      },
    ],
  },
  {
    title: "Consulting",
    logo: await import("~/assets/service/consulting-logo.png"),
    more: "#",
    color: "#a82d1f",
    items: [
      {
        icon: await import("~/assets/service/business.png"),
        title: en ? "Business" : "事業開発",
      },
      {
        icon: await import("~/assets/service/marketing.png"),
        title: en ? "Marketing" : "マーケティング",
      },
      {
        icon: await import("~/assets/service/transformation.png"),
        title: en
          ? "Digital Transformation"
          : "デジタルトランスフォーメーション",
      },
    ],
  },
]);
</script>

<style lang="scss">
.bbbb {
  stroke: #000;
  fill: #000;
  @apply transition-all duration-500;
}

.svg-title {
  font-family: Zen Kaku Gothic New;
}

.cccc:hover {
  .bbbb {
    stroke: #000;
    fill: transparent;
    stroke-width: 1px;
    stroke-dasharray: 9999;
  }
}
</style>
