<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
const support = ref("");

const getBrowserInfo = () => {
  const ua = navigator.userAgent;

  // 检测 Microsoft Edge
  let match = ua.match(/(Edge)\/(\d+\.\d+)/);
  if (match !== null) return { name: match[1], version: match[2] };

  // 检测 Microsoft Internet Explorer
  match = ua.match(/(MSIE) (\d+\.\d+);/);
  if (match !== null) return { name: match[1], version: match[2] };

  // 检测 Chrome
  match = ua.match(/(Chrome)\/(\d+\.\d+)/);
  if (match !== null) return { name: match[1], version: match[2] };

  // 检测 Firefox
  match = ua.match(/(Firefox)\/(\d+\.\d+)/);
  if (match !== null) return { name: match[1], version: match[2] };

  // 检测 Safari
  match = ua.match(/(Safari)\/(\d+\.\d+)/);
  if (match !== null) return { name: match[1], version: match[2] };

  // 其他浏览器或无法检测
  return undefined;
};

onMounted(() => {
  support.value = checkWebMSupport();
});

const checkWebMSupport = () => {
  const result = getBrowserInfo();
  if (result === undefined) return "Not supported";
  if (result.name === "Chrome") {
    return "Supported";
  } else {
    return "Not supported";
  }
};

let width = ref(0);
const minWidth = 640;

const updateWidth = () => {
  width.value = window.innerWidth;
};

onMounted(() => {
  updateWidth();
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});
</script>

<template>
  <div>
    <div>
      <div
        class="sm:border-b relative sm:-z-50 sm:pt-36 sm:border-black sm:pb-60 pb-12 sm:px-32 overflow-hidden sm:-top-36 -top-16 pt-10"
      >
        <video
          src="../assets/video/pc.mp4"
          class="sm:scale-[120%] absolute pointer-events-none sm:left-20 sm:top-10 left-0 top-0 w-full h-full object-cover -z-30"
          autoplay
          muted
          playsinline
          preload="auto"
        ></video>

        <!-- <img
          v-else
          class="sm:hidden scale-125 absolute h-[500px] sm:h-full pointer-events-none left-10 top-0 w-full object-cover -z-10"
          src="~/assets/hero.png"
          alt=""
        /> -->
        <AppHero></AppHero>
      </div>
    </div>

    <AppAbout></AppAbout>
    <AppServices></AppServices>
    <AppWorks></AppWorks>

    <AppClient></AppClient>
    <AppGetInTouch />
  </div>
</template>
<style lang="scss"></style>
