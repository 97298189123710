<template>
  <div class="mx-1">
    <div
      class="text-sm sm:text-3xl second-font font-bold text-center sm:mt-48 mt-20"
    >
      CLIENTS
    </div>
    <div class=" whitespace-nowrap">
      <div
      class="inline-flex ani-scrollRight w-screen items-center h-12 justify-between sm:mt-28"
    >
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/neo.png"
        alt=""
      />
      <img
        style="--scale: 1.7"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/moet.png"
        alt=""
      />
      <img
        style="--scale: 1.3"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/royce.png"
        alt=""
      />
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/shein.png"
        alt=""
      />
      <img
        style="--scale: 1.5"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/ont.png"
        alt=""
      />
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/aww.png"
        alt=""
      />
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/con.png"
        alt=""
      />
      <img
        style="--scale: 1.7"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/saison.png"
        alt=""
      />
      <img
        style="--scale: 1.3"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/netease.png"
        alt=""
      />
    </div>
    
    <div
      class="inline-flex ani-scrollRight ml-10 w-screen items-center h-12 justify-between sm:mt-28"
    >
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/neo.png"
        alt=""
      />
      <img
        style="--scale: 1.7"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/moet.png"
        alt=""
      />
      <img
        style="--scale: 1.3"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/royce.png"
        alt=""
      />
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/shein.png"
        alt=""
      />
      <img
        style="--scale: 1.5"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/ont.png"
        alt=""
      />
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/aww.png"
        alt=""
      />
      <img
        style="--scale: 1"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/con.png"
        alt=""
      />
      <img
        style="--scale: 1.7"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/saison.png"
        alt=""
      />
      <img
        style="--scale: 1.3"
        class="mx-2 max-w-[35px] max-h-[17px] sm:max-w-[100px] sm:max-h-[50px] transform scale-[--scale] origin-center"
        src="~/assets/client/netease.png"
        alt=""
      />
    </div>
    </div>
  </div>
</template>


<style lang="scss">
  @keyframes scrollRight {
  0% {
    transform: translateX(0%); /* 从容器的右侧开始 */
  }
  100% {
    transform: translateX(-100%); /* 滚动到容器的左侧 */
  }
}

.ani-scrollRight {
  animation: scrollRight 10s linear infinite;
}
</style>