<template>
  <div class="app-hero text-center sm:-mt-[20px] mt-28 mb-20">
    <div
      class="hero-title sm:mt-16 ml-12 sm:text-5xl text-xl uppercase font-bold sm:mb-12 mb-2"
    >
      Evolution
    </div>
    <div
      class="hero-content sm:text-[8rem] xl:text-[13rem] text-[3.5rem] font-bold mb-8 sm:mb-30"
    >
      <span>INNOVA</span>
      <!-- <span class="relative -z-50 second-font inline-block">T</span> -->
      <span class="second-font">TING</span>
    </div>
    <div
      class="hero-subtitle ml-10 sm:mr-12 sm:text-4xl text-[16px] uppercase font-bold"
    >
      for a better world
    </div>
  </div>
</template>
